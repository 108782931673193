import './AboutUs.css';
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { localizedStrings } from "../../util/LocalizedStrings";
import Drawer from '../../view/Drawer/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StoresButtons from '../../view/StoresButton/StoresButtons'
import NavigationManager from '../../util/NavigationManager';

export default class AboutUs extends React.Component {

    render() {
        return (
            <div className='RootAboutUs'>
                <span>
                    {localizedStrings.formatString(localizedStrings.aboutUsPageDevelopedBy, <b>{localizedStrings.aboutUsPageAppsmotor}</b>)}
                </span>
                <Paper className='ListView'>
                    <List>
                        <ListItem button divider
                            onClick={() => window.open('mailto:appsmotorrr@gmail.com')}>
                            <ListItemText primary={localizedStrings.aboutUsPageContactUs} />
                        </ListItem>
                        <ListItem button divider
                            onClick={() => window.open('https://www.instagram.com/appsmotor/', '_blank')}>
                            <ListItemText primary={localizedStrings.aboutUsFollowUs} />
                        </ListItem>
                        <ListItem button
                            onClick={() => window.open(NavigationManager.privacyPolicyUrl, '_blank')}>
                            <ListItemText primary={localizedStrings.privacyPolicy} />
                        </ListItem>
                    </List>
                </Paper>
                <Drawer />
                {this.renderFAQ()}
                <div className='Disclaimer'>
                    <b>{localizedStrings.appDisclaimerTitle}:</b>
                    &nbsp;{localizedStrings.appDisclaimer}
                </div>
                <StoresButtons />
            </div>
        );
    }

    renderFAQ() {
        let items = [];
        for (let i = 0; i < 5; i++) {
            let number = i + 1
            items.push({
                title: localizedStrings['faqTitle' + number],
                description: localizedStrings['faqDescription' + number]
            })
        }

        return (
            <div className='FAQ'>
                <div>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{localizedStrings.faqGeneralTitle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {
                                    items.map((item, i) =>
                                        <Accordion key={i}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>{item.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant='body2'>{item.description}</Typography>
                                            </AccordionDetails>
                                        </Accordion>)
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
}
import React from 'react';
import './StoresButtons.css';

export const PHOTO_SHERLOCK_GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=hippeis.com.photochecker";
export const PHOTO_SHERLOCK_APP_STORE_LINK = "https://apps.apple.com/app/photo-sherlock-search-by-image/id1270107821";

export default class StoresButtons extends React.Component {
    render() {
        return <div className='StoresButtonsContainer'>
            <a target="_blank" rel="noopener noreferrer" href={PHOTO_SHERLOCK_GOOGLE_PLAY_LINK}>
                <img height="45px" src="images/google-play-badge.png" alt="Download on Google Play" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={PHOTO_SHERLOCK_APP_STORE_LINK}>
                <img height="45px" src="images/app-store-badge.png" alt="Download on App Store" />
            </a>
        </div>
    }
}
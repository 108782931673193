import LocalizedStrings from 'react-localization';

export let localizedStrings = new LocalizedStrings({
    en: {
        // App head
        appTitle: 'Photo Sherlock: search by image',
        appMetaDescription: 'Get information about photo on Internet in few taps',

        // SearchImage page
        searchImagePageDropImageText: 'Drop image here',
        searchImagePageSearchButton: 'Search',
        searchImagePageSearchInBingButton: 'Bing',
        searchImagePageSearchWithFaceSherlockButton: 'Face Sherlock',
        searchImagePageSearchWithActorSherlockButton: 'Actor Sherlock',
        searchImagePageLinkDescriptionText: 'Link will be available only 24 hours',
        searchImagePageImagePreviewAlt: 'Preview',
        searchImagePageIhancerAdMessage: 'Enhance photo quality with {0} app to have better search results',
        searchImagePageSelferAdMessage: 'Take a photo with {0} app',
        searchImagePageWNTDAdMessage: 'Looking for a person? Try our app',
        searchImagePageGamezopAdMessage: 'Do you want to play a game?',
        searchImagePageCropText: 'Crop',
        WNTD: 'WNTD',
        supportUkraine: 'Support Ukraine',

        // App description
        aboutApp: 'About app',
        appPromoTitle: 'Search by photo on Internet in few taps',
        appDescription: 'App provides search by image taken from camera or from gallery. Can be used to find information about photo on Internet, for example to detect real owner of photo from social network (check if photo is fake). Contains crop functionality.',

        features: 'Features',
        appFeature1: 'Take photos fast and easily',
        appFeature2: 'Possibility to choose photo from gallery',
        appFeature3: 'Search by image with search engines',
        appFeature4: 'Possibility to crop image before search to remove unwanted regions',
        appFeature5: 'Image size reduced automatically to provide fast search experience',

        typicalUseCases: 'Typical use cases',
        appUseCase1: 'Search person by photo',
        appUseCase2: 'Detect if photo is fake',
        appUseCase3: 'Search by photo in social networks like Instagram, Facebook and other',
        appUseCase4: 'Search web pages where the photo is used',
        appUseCase5: 'Confirm that person from dating app is real',
        appUseCase6: 'Search original photo',
        appUseCase7: 'Search photo with better quality',
        appUseCase8: 'Recognition by photo',
        appUseCase9: 'Search products by photo',
        appUseCase10: 'Search clothes by photo',
        appUseCase11: 'Search by face',
        appUseCase12: 'Search clones by photo',

        appDisclaimerTitle: 'Disclaimer',
        appDisclaimer: 'we are not affiliated, associated, authorized, endorsed by, or in any way officially connected with Bing search engine.',

        // FAQ
        faqGeneralTitle: 'FAQ',

        faqTitle1: 'How it can be used?',
        faqDescription1: 'You can find the source of the image, information about it, on which sites it was used',

        faqTitle2: 'Want to check person photo for fake?',
        faqDescription2: 'You can try to check it, if photo is found on Internet there is possibility to know who it belongs to',

        faqTitle3: 'How it works?',
        faqDescription3: 'You just upload photo by tapping on image square area, drag and drop, or take photo from camera then app generates links to the search engines you can open',

        faqTitle4: 'Why not just use search engines directly?',
        faqDescription4: 'Because it\'s not so convenient, you need to upload photo for every search engine, here you upload once with optimized resolution. Also if photo contains some unwanted areas you can crop it to improve the chances of being found.',

        faqTitle5: 'Are the generated links expiring?',
        faqDescription5: 'Yes, link is available for 24 hours, please note this if you are going to share it',

        // AboutUs page
        aboutUsPageDevelopedBy: 'Developed by {0}',
        aboutUsPageAppsmotor: 'appsmotor',
        aboutUsPageContactUs: 'Contact Us',
        aboutUsFollowUs: 'Follow Us',

        // General
        privacyPolicy: 'Privacy Policy',

        // Redirect page
        redirectPageOpen: 'Open',

        // Drawer
        drawerItemSearch: 'Search',
        drawerItemAboutUs: 'About Us',

        // Error
        errorSomethingWentWrong: 'Something went wrong, try again later',
        errorTooManyRequests: 'You make requests too often, please try again later',
        errorContentFilter: 'Image contains nudity. Please crop the image so that it does not contain nudity.',

        // Cookie consnet
        cookieConsentMessage: 'This website uses cookies to enhance the user experience.',
        cookieConsentButton: 'Got it!',
    },

    ru: {
        // App head
        appTitle: 'Photo Sherlock - поиск по изображению',
        appMetaDescription: 'Получи информацию о фотографии в Интернете в несколько касаний',

        // SearchImage page
        searchImagePageDropImageText: 'Перетащите изображение сюда',
        searchImagePageSearchButton: 'Искать',
        searchImagePageLinkDescriptionText: 'Ссылка будет доступна только 24 часа',
        searchImagePageImagePreviewAlt: 'Предварительный просмотр',
        searchImagePageIhancerAdMessage: 'Повысьте качество фотографий с помощью приложения {0}, чтобы получить лучшие результаты поиска',
        searchImagePageSelferAdMessage: 'Сделать фото с приложением {0}',
        searchImagePageWNTDAdMessage: 'Ищете человека? Попробуйте наше приложение',
        searchImagePageGamezopAdMessage: 'Хочешь сыграть в игру?',
        searchImagePageCropText: 'Обрезать',
        supportUkraine: 'Поддержите Украину',

        // App description
        aboutApp: 'О приложении',
        appPromoTitle: 'Поиск по фото в Интернете в несколько касаний',
        appDescription: 'Приложение предоставляет поиск по фото из камеры или галереи. Можно использовать чтобы найти информацию о картинке в Интернете, например чтобы проверить кому действительно принадлежит фото из социальной сети (проверка на фейк). Есть возможность сразу обрезать фото.',

        features: 'Функции',
        appFeature1: 'Делайте фотографии быстро и легко',
        appFeature2: 'Возможность выбрать фото из галереи',
        appFeature3: 'Поиск по изображению с помощью поисковой системы',
        appFeature4: 'Возможность обрезать изображение перед поиском, чтобы убрать ненужные области',
        appFeature5: 'Размер изображения автоматически уменьшается для обеспечения быстрого поиска',

        typicalUseCases: 'Типичные сценарии использования',
        appUseCase1: 'Поиск человека по фото',
        appUseCase2: 'Определение является ли фотография поддельной(Фейк)',
        appUseCase3: 'Поиск по фото в социальных сетях, таких как Instagram, Facebook и др.',
        appUseCase4: 'Поиск веб страниц, где используется фотография',
        appUseCase5: 'Проверка что человек из приложений для знакомств настоящий',
        appUseCase6: 'Поиск оригинального фото',
        appUseCase7: 'Поиск фото в лучшем качестве',
        appUseCase8: 'Распознавание по фото',
        appUseCase9: 'Поиск товаров по фото',
        appUseCase10: 'Поиск одежды по фото',
        appUseCase11: 'Поиск по лицу',
        appUseCase12: 'Поиск клонов по фото',

        // FAQ
        faqGeneralTitle: 'Вопросы-Ответы',

        faqTitle1: 'Как это можно использовать?',
        faqDescription1: 'Вы можете найти источник изображения, информацию о нем, на каких сайтах оно использовалось',

        faqTitle2: 'Хотите проверить фото человека на подделку?',
        faqDescription2: 'Вы можете попробовать проверить это, если фотография найдена в Интернете, есть возможность узнать кому она принадлежит',

        faqTitle3: 'Как это работает?',
        faqDescription3: 'Вы просто загружаете фотографию, нажав на квадратную область, можно также перетащить или сделать снимок с камеры, после чего приложение генерирует ссылки на поисковые системы, которые вы можете открыть',

        faqTitle4: 'Почему бы просто не использовать поисковые системы напрямую?',
        faqDescription4: 'Потому что это не так удобно, вам нужно загружать фото для каждой поисковой системы, здесь вы загружаете один раз с оптимизированным разрешением. Также, если фотография содержит нежелательные области, вы можете обрезать ее, чтобы повысить вероятность поиска.',

        faqTitle5: 'Срок действия созданных ссылок истекает?',
        faqDescription5: 'Да, ссылка доступна в течение 24 часов, обратите на это внимание если вы собираетесь ею поделиться',

        // AboutUs page
        aboutUsPageDevelopedBy: 'Разработано {0}',
        aboutUsPageContactUs: 'Связаться с нами',
        aboutUsFollowUs: 'Подписывайтесь на нас',

        // General
        privacyPolicy: 'Политика конфиденциальности',

        // Redirect page
        redirectPageOpen: 'Открыть',

        // Drawer
        drawerItemSearch: 'Поиск',
        drawerItemAboutUs: 'О нас',

        // Error
        errorSomethingWentWrong: 'Что-то пошло не так, попробуйте позже',
        errorTooManyRequests: 'Вы делаете запросы слишком часто, повторите попытку позже',
        errorContentFilter: 'Изображение содержит наготу. Пожалуйста, обрежьте изображение, чтобы оно не содержало обнаженных тел.',

        // Cookie consnet
        cookieConsentMessage: 'Этот веб-сайт использует куки для улучшения пользовательского опыта.',
        cookieConsentButton: 'Понятно!',
    },

    uk: {
        // App head
        appTitle: 'Photo Sherlock - пошук за зображенням',
        appMetaDescription: 'Отримай інформацію про фотогорафію в Інтернеті в декілька дотиків',

        // SearchImage page
        searchImagePageDropImageText: 'Перетягніть зображення сюди',
        searchImagePageSearchButton: 'Шукати',
        searchImagePageLinkDescriptionText: 'Посилання буде доступна тільки 24 години',
        searchImagePageImagePreviewAlt: 'Попередній перегляд',
        searchImagePageIhancerAdMessage: 'Покращуйте якість фотографій за допомогою програми {0}, щоб отримувати кращі результати пошуку',
        searchImagePageSelferAdMessage: 'Сфотографувати з додатком {0}',
        searchImagePageWNTDAdMessage: 'Шукаєте людину? Спробуйте наш додаток',
        searchImagePageGamezopAdMessage: 'Ви хочете зіграти в гру?',
        searchImagePageCropText: 'Обрізати',
        supportUkraine: 'Підтримайте Україну',

        // App description
        aboutApp: 'Про додаток',
        appPromoTitle: 'Пошук по фото в Інтернеті в декілька дотиків',
        appDescription: 'Додаток шукає в інтернеті фото з камери чи галереї. Можна використовувати щоб знайти інформацію про зображення в Інтернеті, наприклад щоб перевірити кому дійсно належить фото з соціальної мережі (перевірка на фейк). Є можливість відразу обрізати фото.',

        features: 'Функції',
        appFeature1: 'Робіть фотографії швидко і легко',
        appFeature2: 'Можливість вибрати фото з галереї',
        appFeature3: 'Пошук по зображенню за допомогою пошукової системи',
        appFeature4: 'Можливість обрізати зображення перед пошуком, щоб прибрати непотрібні області',
        appFeature5: 'Розмір зображення автоматично зменшується для забезпечення швидкого пошуку',

        typicalUseCases: 'Типові сценарії використання',
        appUseCase1: 'Пошук людини по фото',
        appUseCase2: 'Перевірка чи є фотографія підробленою(Фейк)',
        appUseCase3: 'Пошук по фото в соціальних мережах, таких як Instagram, Facebook і ін.',
        appUseCase4: 'Пошук веб сторінок, де використовується фотографія',
        appUseCase5: 'Перевірка що людина з додатків для знайомств справжня',
        appUseCase6: 'Пошук оригінального фото',
        appUseCase7: 'Пошук фото в кращій якості',
        appUseCase8: 'Розпізнавання по фото',
        appUseCase9: 'Пошук товарів по фото',
        appUseCase10: 'Пошук одягу по фото',
        appUseCase11: 'Пошук по обличчю',
        appUseCase12: 'Пошук клонів по фото',

        // AboutUs page
        aboutUsPageDevelopedBy: 'Розроблено {0}',
        aboutUsPageContactUs: 'Зв\'язатись з нами',
        aboutUsFollowUs: 'Слідуй за нами',

        // General
        privacyPolicy: 'Політика конфіденційності',

        // Redirect page
        redirectPageOpen: 'Відкрити',

        // Drawer
        drawerItemSearch: 'Пошук',
        drawerItemAboutUs: 'Про нас',

        // Error
        errorSomethingWentWrong: 'Щось пішло не так, спробуйте пізніше',
        errorTooManyRequests: 'Ви надто часто надсилаєте запити, повторіть спробу пізніше',
        errorContentFilter: 'Зображення містить наготу. Будь ласка, обріжте зображення, щоб на ньому не було оголеного тіла.',

        // Cookie consnet
        cookieConsentMessage: 'Цей веб-сайт використовує кукі для поліпшення користувацького досвіду.',
        cookieConsentButton: 'Зрозуміло!',
    },

    es: {
        // App head
        appTitle: 'Photo Sherlock: búsqueda por imagen',
        appMetaDescription: 'Obtenga información sobre fotos en Internet en unos pocos toques',

        // SearchImage page
        searchImagePageDropImageText: 'Suelta la imagen aquí',
        searchImagePageSearchButton: 'Buscar',
        searchImagePageLinkDescriptionText: 'El enlace estará disponible solo 24 horas',
        searchImagePageImagePreviewAlt: 'Avance',
        searchImagePageIhancerAdMessage: 'Mejore la calidad de las fotos con la aplicación {0} para obtener mejores resultados de búsqueda',
        searchImagePageSelferAdMessage: 'Tome una foto con la aplicación {0}',
        searchImagePageWNTDAdMessage: 'Buscando una persona? Prueba nuestra aplicación',
        searchImagePageGamezopAdMessage: '¿Quieres jugar un juego?',
        searchImagePageCropText: 'Delimitar',
        supportUkraine: 'Apoya a Ucrania',

        // App description
        aboutApp: 'Acerca de la aplicación',
        appPromoTitle: 'Busque por foto en Internet en unos pocos toques',
        appDescription: 'La aplicación proporciona búsqueda por imagen tomada de la cámara o de la galería. Se puede utilizar para buscar información sobre una foto en Internet, por ejemplo, para detectar al propietario real de la foto en una red social (compruebe si la foto es falsa). Contiene funcionalidad de cultivo.',

        features: 'Características',
        appFeature1: 'Toma fotos rápida y fácilmente',
        appFeature2: 'Posibilidad de elegir una foto de la galería.',
        appFeature3: 'Búsqueda por imagen con motores de búsqueda',
        appFeature4: 'Posibilidad de recortar la imagen antes de la búsqueda para eliminar regiones no deseadas',
        appFeature5: 'El tamaño de la imagen se reduce automáticamente para proporcionar una experiencia de búsqueda rápida',

        typicalUseCases: 'Casos de uso típicos',
        appUseCase1: 'Buscar persona por foto',
        appUseCase2: 'Detectar si la foto es falsa',
        appUseCase3: 'Búsqueda por foto en redes sociales como Instagram, Facebook y otras',
        appUseCase4: 'Buscar páginas web donde se usa la foto.',
        appUseCase5: 'Confirma que la persona de la aplicación de citas es real',
        appUseCase6: 'Buscar foto original',
        appUseCase7: 'Buscar foto con mejor calidad',
        appUseCase8: 'Reconocimiento por foto',
        appUseCase9: 'Buscar productos por foto',
        appUseCase10: 'Buscar ropa por foto',
        appUseCase11: 'Búsqueda por cara',
        appUseCase12: 'Buscar clones por foto',

        // AboutUs page
        aboutUsPageDevelopedBy: 'Desarrollado por {0}',
        aboutUsPageContactUs: 'Contacta con nosotros',
        aboutUsFollowUs: 'Síguenos',

        // General
        privacyPolicy: 'Política de privacidad',

        // Redirect page
        redirectPageOpen: 'Abierto',

        // Drawer
        drawerItemSearch: 'Buscar',
        drawerItemAboutUs: 'Sobre nosotros',

        // Error
        errorSomethingWentWrong: 'Algo salió mal, inténtalo de nuevo más tarde',
        errorTooManyRequests: 'Realiza solicitudes con demasiada frecuencia, inténtelo de nuevo más tarde',
        errorContentFilter: 'La imagen contiene desnudos. Recorte la imagen para que no contenga desnudos.',

        // Cookie consnet
        cookieConsentMessage: 'Este sitio web utiliza cookies para mejorar la experiencia del usuario.',
        cookieConsentButton: '¡Entiendo!',
    },

    de: {
        // App head
        appTitle: 'Photo Sherlock: suche nach Bild',
        appMetaDescription: 'Holen Sie sich Informationen über Fotos im Internet in wenigen Schritten',

        // SearchImage page
        searchImagePageDropImageText: 'Bild hier ablegen',
        searchImagePageSearchButton: 'Suche',
        searchImagePageLinkDescriptionText: 'Der Link ist nur 24 Stunden verfügbar',
        searchImagePageImagePreviewAlt: 'Vorschau',
        searchImagePageIhancerAdMessage: 'Verbessern Sie die Fotoqualität mit der App {0}, um bessere Suchergebnisse zu erhalten',
        searchImagePageSelferAdMessage: 'Machen Sie ein Foto mit {0} App',
        searchImagePageWNTDAdMessage: 'Auf der Suche nach einer Person? Probieren Sie unsere App aus',
        searchImagePageGamezopAdMessage: 'Willst du ein Spiel spielen?',
        searchImagePageCropText: 'Zuschneiden',
        supportUkraine: 'Unterstützen Sie die Ukraine',

        // App description
        aboutApp: 'Über App.',
        appPromoTitle: 'Suche nach Foto im Internet in wenigen Klicks',
        appDescription: 'Die App bietet die Suche nach Bildern, die von der Kamera oder aus der Galerie aufgenommen wurden. Kann verwendet werden, um Informationen über Fotos im Internet zu finden, z. B. um den echten Besitzer des Fotos aus dem sozialen Netzwerk zu erkennen (prüfen Sie, ob das Foto gefälscht ist). Enthält Zuschneidefunktion.',

        features: 'Merkmale',
        appFeature1: 'Schnell und einfach Fotos machen',
        appFeature2: 'Möglichkeit, ein Foto aus der Galerie auszuwählen',
        appFeature3: 'Suche nach Bildern mit Suchmaschinen',
        appFeature4: 'Möglichkeit, das Bild vor der Suche zuzuschneiden, um unerwünschte Bereiche zu entfernen',
        appFeature5: 'Die Bildgröße wird automatisch reduziert, um eine schnelle Suche zu ermöglichen',

        typicalUseCases: 'Typische Anwendungsfälle',
        appUseCase1: 'Person nach Foto suchen',
        appUseCase2: 'Erkennen Sie, ob das Foto gefälscht ist',
        appUseCase3: 'Suche nach Foto in sozialen Netzwerken wie Instagram, Facebook und anderen',
        appUseCase4: 'Durchsuchen Sie Webseiten, auf denen das Foto verwendet wird',
        appUseCase5: 'Bestätige, dass die Person aus der Dating-App echt ist',
        appUseCase6: 'Suche nach Originalfoto',
        appUseCase7: 'Foto mit besserer Qualität suchen',
        appUseCase8: 'Erkennung per Foto',
        appUseCase9: 'Produkte nach Foto suchen',
        appUseCase10: 'Suche Kleidung nach Foto',
        appUseCase11: 'Suche nach Gesicht',
        appUseCase12: 'Suche nach Klonen nach Foto',
        
        // AboutUs page
        aboutUsPageDevelopedBy: 'Entwickelt von {0}',
        aboutUsPageContactUs: 'Kontaktiere uns',
        aboutUsFollowUs: 'Folge uns',

        // General
        privacyPolicy: 'Datenschutz-Bestimmungen',

        // Redirect page
        redirectPageOpen: 'Offen',

        // Drawer
        drawerItemSearch: 'Suche',
        drawerItemAboutUs: 'Über uns',

        // Error
        errorSomethingWentWrong: 'Es ist ein Fehler aufgetreten. Versuchen Sie es später erneut',
        errorTooManyRequests: 'Sie stellen zu oft Anfragen. Bitte versuchen Sie es später erneut',
        errorContentFilter: 'Das Bild enthält Nacktheit. Bitte beschneiden Sie das Bild so, dass es keine Nacktheit enthält.',

        // Cookie consnet
        cookieConsentMessage: 'Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.',
        cookieConsentButton: 'Ich habs!',
    },

    pt: {
        // App head
        appTitle: 'Photo Sherlock: busca por imagem',
        appMetaDescription: 'Obtenha informações sobre fotos na Internet em poucos toques',

        // SearchImage page
        searchImagePageDropImageText: 'Solte a imagem aqui',
        searchImagePageSearchButton: 'Procurar',
        searchImagePageLinkDescriptionText: 'O link estará disponível apenas 24 horas',
        searchImagePageImagePreviewAlt: 'Antevisão',
        searchImagePageIhancerAdMessage: 'Melhore a qualidade da foto com o aplicativo {0} para obter melhores resultados de pesquisa',
        searchImagePageSelferAdMessage: 'Tire uma foto com {0} aplicativo',
        searchImagePageWNTDAdMessage: 'Procurando por uma pessoa? Experimente nosso aplicativo',
        searchImagePageGamezopAdMessage: 'você quer jogar um jogo?',
        searchImagePageCropText: 'Cortar',
        supportUkraine: 'Apoie a Ucrânia',

        // App description
        aboutApp: 'Sobre app.',
        appPromoTitle: 'Pesquise por foto na Internet com alguns toques',
        appDescription: 'O aplicativo fornece pesquisa por imagem tirada da câmera ou da galeria. Pode ser usado para encontrar informações sobre foto na Internet, por exemplo, para detectar o verdadeiro dono da foto da rede social (verifique se a foto é falsa). Contém funcionalidade de corte.',

        features: 'Recursos',
        appFeature1: 'Tire fotos com rapidez e facilidade',
        appFeature2: 'Possibilidade de escolher foto da galeria',
        appFeature3: 'Pesquise por imagem com os motores de busca',
        appFeature4: 'Possibilidade de recortar a imagem antes da pesquisa para remover regiões indesejadas',
        appFeature5: 'Tamanho da imagem reduzido automaticamente para fornecer experiência de pesquisa rápida',

        typicalUseCases: 'Casos de uso típicos',
        appUseCase1: 'Pesquisar pessoa por foto',
        appUseCase2: 'Detectar se a foto é falsa',
        appUseCase3: 'Pesquise por foto em redes sociais como Instagram, Facebook e outras',
        appUseCase4: 'Pesquise páginas da web onde a foto é usada',
        appUseCase5: 'Confirme se a pessoa do aplicativo de namoro é real',
        appUseCase6: 'Pesquisar foto original',
        appUseCase7: 'Pesquisar foto com melhor qualidade',
        appUseCase8: 'Reconhecimento por foto',
        appUseCase9: 'Pesquisar produtos por foto',
        appUseCase10: 'Pesquisar roupas por foto',
        appUseCase11: 'Pesquisa por cara',
        appUseCase12: 'Pesquisar clones por foto',
        
        // AboutUs page
        aboutUsPageDevelopedBy: 'Desenvolvido por {0}',
        aboutUsPageContactUs: 'Contate-Nos',
        aboutUsFollowUs: 'Siga-nos',

        // General
        privacyPolicy: 'Política de Privacidade',

        // Redirect page
        redirectPageOpen: 'Aberto',

        // Drawer
        drawerItemSearch: 'Pesquisa',
        drawerItemAboutUs: 'Sobre nós',

        // Error
        errorSomethingWentWrong: 'Algo deu errado, tente novamente mais tarde',
        errorTooManyRequests: 'Você faz solicitações com muita frequência, tente novamente mais tarde',
        errorContentFilter: 'A imagem contém nudez. Corte a imagem para que não contenha nudez.',

        // Cookie consnet
        cookieConsentMessage: 'Este site usa cookies para melhorar a experiência do usuário.',
        cookieConsentButton: 'Entendi!',
    },

    zh: {
        // App head
        appTitle: 'Photo Sherlock: 通过图像搜索',
        appMetaDescription: '轻点几下即可获取有关 Internet上照片的信息',

        // SearchImage page
        searchImagePageDropImageText: '将图片放在这里',
        searchImagePageSearchButton: '搜索',
        searchImagePageLinkDescriptionText: '链接仅24小时可用',
        searchImagePageImagePreviewAlt: '预习',
        searchImagePageIhancerAdMessage: '使用 {0} 应用提高照片质量以获得更好的搜索结果',
        searchImagePageSelferAdMessage: '用{0}应用拍照',
        searchImagePageWNTDAdMessage: '寻找一个人？ 试试我们的应用',
        searchImagePageGamezopAdMessage: '你要玩个游戏吗？',
        searchImagePageCropText: '修剪',
        supportUkraine: '支持乌克兰',

        // App description
        aboutApp: '关于应用程序',
        appPromoTitle: '轻点几下即可在 Internet上按照片搜索',
        appDescription: '应用程序提供从相机或画廊拍摄的图像搜索。可用于在 Internet 上查找有关照片的信息，例如从社交网络中检测照片的真实所有者（检查照片是否是假的）。包含裁剪功能。',

        features: '特征',
        appFeature1: '快速轻松地拍照',
        appFeature2: '可以从图库中选择照片',
        appFeature3: '使用搜索引擎按图片搜索',
        appFeature4: '可以在搜索之前裁剪图像以删除不需要的区域',
        appFeature5: '图像大小自动缩小以提供快速搜索体验',

        typicalUseCases: '典型用例',
        appUseCase1: '按照片搜索人物',
        appUseCase2: '检测照片是否是假的',
        appUseCase3: '在 Instagram、Facebook 等社交网络中按照片搜索',
        appUseCase4: '搜索使用照片的网页',
        appUseCase5: '确认约会应用程序中的人是真实的',
        appUseCase6: '搜索原始照片',
        appUseCase7: '搜索质量更好的照片',
        appUseCase8: '通过照片识别',
        appUseCase9: '按照片搜索产品',
        appUseCase10: '按照片搜索衣服',
        appUseCase11: '按人脸搜索',
        appUseCase12: '按照片搜索克隆',

        // AboutUs page
        aboutUsPageDevelopedBy: '由{0}开发',
        aboutUsPageContactUs: '联系我们',
        aboutUsFollowUs: '跟着我们',

        // General
        privacyPolicy: '隐私政策',

        // Redirect page
        redirectPageOpen: '打开',

        // Drawer
        drawerItemSearch: '搜索',
        drawerItemAboutUs: '搜寻',

        // Error
        errorSomethingWentWrong: '出问题了，请稍后再试',
        errorTooManyRequests: '您的请求过于频繁，请稍后再试',
        errorContentFilter: '图片包含裸体。 请裁剪图像，使其不包含裸体。',

        // Cookie consnet
        cookieConsentMessage: '本网站使用cookie来增强用户体验。',
        cookieConsentButton: '知道了！',
    },

    hi: {
        // App head
        appTitle: 'Photo Sherlock: छवि द्वारा खोज',
        appMetaDescription: 'इंटरनेट (गूगल, यांडेक्स, बिंग) पर फोटो के बारे में कुछ ही टैप में जानकारी प्राप्त करें',

        // SearchImage page
        searchImagePageDropImageText: 'छवि यहाँ छोड़ें',
        searchImagePageSearchButton: 'खोज',
        searchImagePageLinkDescriptionText: 'लिंक केवल 24 घंटे उपलब्ध होगा',
        searchImagePageImagePreviewAlt: 'पूर्वावलोकन',
        searchImagePageIhancerAdMessage: 'बेहतर खोज परिणाम प्राप्त करने के लिए {0} ऐप के साथ फ़ोटो की गुणवत्ता बढ़ाएँ',
        searchImagePageSelferAdMessage: '{0} ऐप के साथ एक फोटो लें',
        searchImagePageWNTDAdMessage: 'किसी व्यक्ति की तलाश है? हमारे app की कोशिश करो',
        searchImagePageGamezopAdMessage: 'क्या आप एक खेल खेलना चाहते हो?',
        searchImagePageCropText: 'ट्रिम',
        supportUkraine: 'यूक्रेन का समर्थन करें',

        // App description
        aboutApp: 'ऐप के बारे में',
        appPromoTitle: 'कुछ ही टैप में इंटरनेट (गूगल, यांडेक्स, बिंग) पर फोटो द्वारा खोजें',
        appDescription: 'ऐप कैमरे से या गैलरी से ली गई छवि द्वारा खोज प्रदान करता है। इंटरनेट पर फोटो के बारे में जानकारी खोजने के लिए इस्तेमाल किया जा सकता है, उदाहरण के लिए सोशल नेटवर्क से फोटो के असली मालिक का पता लगाने के लिए (अगर फोटो नकली है)। फसल की कार्यक्षमता शामिल है।',

        features: 'विशेषताएं',
        appFeature1: 'तेजी से और आसानी से तस्वीरें लें',
        appFeature2: 'गैलरी से फोटो चुनने की संभावना',
        appFeature3: 'खोज इंजन के साथ छवि द्वारा खोजें',
        appFeature4: 'अवांछित क्षेत्रों को हटाने के लिए खोज से पहले छवि को क्रॉप करने की संभावना',
        appFeature5: 'तेजी से खोज अनुभव प्रदान करने के लिए छवि का आकार स्वचालित रूप से कम हो गया',

        typicalUseCases: 'विशिष्ट उपयोग के मामले',
        appUseCase1: 'फोटो द्वारा व्यक्ति खोजें',
        appUseCase2: 'पता लगाएं कि फोटो नकली है',
        appUseCase3: 'Instagram, Facebook और अन्य जैसे सामाजिक नेटवर्क में फ़ोटो द्वारा खोजें',
        appUseCase4: 'उन वेब पेजों को खोजें जहां फोटो का उपयोग किया जाता है',
        appUseCase5: 'पुष्टि करें कि डेटिंग ऐप से वह व्यक्ति असली है',
        appUseCase6: 'मूल फोटो खोजें',
        appUseCase7: 'बेहतर गुणवत्ता के साथ फोटो खोजें',
        appUseCase8: 'फोटो द्वारा पहचान',
        appUseCase9: 'फोटो द्वारा उत्पादों को खोजें',
        appUseCase10: 'फोटो द्वारा कपड़े खोजें',
        appUseCase11: 'चेहरे से खोजें',
        appUseCase12: 'फोटो द्वारा क्लोन खोजें',

        // AboutUs page
        aboutUsPageDevelopedBy: '{0} द्वारा विकसित',
        aboutUsPageContactUs: 'संपर्क करें',
        aboutUsFollowUs: 'हमारा अनुसरण करें',

        // General
        privacyPolicy: 'गोपनीयता नीति',

        // Redirect page
        redirectPageOpen: 'खोलना',

        // Drawer
        drawerItemSearch: 'खोज',
        drawerItemAboutUs: 'हमारे बारे में',

        // Error
        errorSomethingWentWrong: 'कुछ गलत हुआ, बाद में फिर से प्रयास करें',
        errorTooManyRequests: 'आप बहुत बार अनुरोध करते हैं, कृपया बाद में पुनः प्रयास करें',
        errorContentFilter: 'छवि में नग्नता है। कृपया छवि को क्रॉप करें ताकि उसमें नग्नता न हो।',

        // Cookie consnet
        cookieConsentMessage: 'यह वेबसाइट उपयोगकर्ता अनुभव को बढ़ाने के लिए कुकीज़ का उपयोग करती है।',
        cookieConsentButton: 'समझ गया!',
    },

    fr: {
        // App head
        appTitle: 'Photo Sherlock: recherche par image',
        appMetaDescription: 'Obtenez des informations sur la photo sur Internet en quelques clics',

        // SearchImage page
        searchImagePageDropImageText: 'Déposer l\'image ici',
        searchImagePageSearchButton: 'Chercher',
        searchImagePageLinkDescriptionText: 'Le lien ne sera disponible que 24 heures',
        searchImagePageImagePreviewAlt: 'Aperçu',
        searchImagePageIhancerAdMessage: 'Améliorez la qualité des photos avec l\'application {0} pour obtenir de meilleurs résultats de recherche',
        searchImagePageSelferAdMessage: 'Prenez une photo avec l\'application {0}',
        searchImagePageWNTDAdMessage: 'Vous cherchez une personne? Essayez notre appli',
        searchImagePageGamezopAdMessage: 'Veux-tu jouer à un jeu?',
        searchImagePageCropText: 'Recadrer',
        supportUkraine: 'Soutenir l\'Ukraine',

        // App description
        aboutApp: 'À propos de l\'application',
        appPromoTitle: 'Recherche par photo sur Internet en quelques clics',
        appDescription: 'L\'application fournit une recherche par image prise de l\'appareil photo ou de la galerie. Peut être utilisé pour trouver des informations sur la photo sur Internet, par exemple pour détecter le vrai propriétaire de la photo du réseau social (vérifier si la photo est fausse). Contient la fonctionnalité de recadrage.',

        features: 'Caractéristiques',
        appFeature1: 'Prenez des photos rapidement et facilement',
        appFeature2: 'Possibilité de choisir une photo dans la galerie',
        appFeature3: 'Recherche par image avec les moteurs de recherche',
        appFeature4: 'Possibilité de recadrer l\'image avant la recherche pour supprimer les zones indésirables',
        appFeature5: 'Taille de l\'image réduite automatiquement pour offrir une expérience de recherche rapide',

        typicalUseCases: 'Cas d\'utilisation typiques',
        appUseCase1: 'Rechercher une personne par photo',
        appUseCase2: 'Détecter si la photo est fausse',
        appUseCase3: 'Recherche par photo dans les réseaux sociaux comme Instagram, Facebook et autres',
        appUseCase4: 'Rechercher des pages Web où la photo est utilisée',
        appUseCase5: 'Confirmez que la personne de l\'application de rencontres est réelle',
        appUseCase6: 'Rechercher une photo originale',
        appUseCase7: 'Rechercher une photo avec une meilleure qualité',
        appUseCase8: 'Reconnaissance par photo',
        appUseCase9: 'Rechercher des produits par photo',
        appUseCase10: 'Rechercher des vêtements par photo',
        appUseCase11: 'Recherche par visage',
        appUseCase12: 'Rechercher des clones par photo',

        // AboutUs page
        aboutUsPageDevelopedBy: 'Développé par {0}',
        aboutUsPageContactUs: 'Nous contacter',
        aboutUsFollowUs: 'Suivez nous',

        // General
        privacyPolicy: 'Politique de confidentialité',

        // Redirect page
        redirectPageOpen: 'Ouvrir',

        // Drawer
        drawerItemSearch: 'Chercher',
        drawerItemAboutUs: 'À propos de nous',

        // Error
        errorSomethingWentWrong: 'Une erreur s\'est produite, réessayez plus tard',
        errorTooManyRequests: 'Vous faites des demandes trop souvent, veuillez réessayer plus tard',
        errorContentFilter: 'L\'image contient de la nudité. Veuillez recadrer l\'image afin qu\'elle ne contienne pas de nudité.',

        // Cookie consnet
        cookieConsentMessage: 'Ce site Web utilise des cookies pour améliorer l\'expérience utilisateur.',
        cookieConsentButton: 'J\'ai compris!',
    },

    ja: {
        // App head
        appTitle: 'Photo Sherlock: 画像で検索',
        appMetaDescription: '数回タップするだけでインターネットの写真に関する情報を取得できます',

        // SearchImage page
        searchImagePageDropImageText: 'ここに画像をドロップ',
        searchImagePageSearchButton: '探す',
        searchImagePageLinkDescriptionText: 'リンクは24時間のみ利用可能になります',
        searchImagePageImagePreviewAlt: 'プレビュー',
        searchImagePageIhancerAdMessage: '{0} アプリで写真の品質を高めて、検索結果を向上させます',
        searchImagePageSelferAdMessage: '{0}アプリで写真を撮ります',
        searchImagePageWNTDAdMessage: '人をお探しですか？ 私たちのアプリをお試しください',
        searchImagePageGamezopAdMessage: 'ゲームをしたいですか？',
        searchImagePageCropText: 'トリム',
        supportUkraine: 'ウクライナを支援する',

        // App description
        aboutApp: 'アプリについて',
        appPromoTitle: '数回タップするだけでインターネットの写真で検索',
        appDescription: 'アプリは、カメラまたはギャラリーから撮影した画像による検索を提供します。インターネット上の写真に関する情報を検索するために使用できます。たとえば、ソーシャルネットワークから写真の実際の所有者を検出します（写真が偽物であるかどうかを確認します）。切り抜き機能が含まれています。',

        features: '特徴',
        appFeature1: 'すばやく簡単に写真を撮る',
        appFeature2: 'ギャラリーから写真を選択する可能性',
        appFeature3: '検索エンジンで画像から検索',
        appFeature4: '検索前に画像をトリミングして不要な領域を削除する可能性',
        appFeature5: '画像サイズが自動的に縮小され、高速な検索エクスペリエンスが提供されます',

        typicalUseCases: '典型的な使用例',
        appUseCase1: '写真で人を検索',
        appUseCase2: '写真が偽物かどうかを検出する',
        appUseCase3: 'Instagram、Facebookなどのソーシャルネットワークで写真で検索',
        appUseCase4: '写真が使用されているWebページを検索します',
        appUseCase5: '出会い系アプリの人が本物であることを確認する',
        appUseCase6: '元の写真を検索',
        appUseCase7: 'より良い品質で写真を検索する',
        appUseCase8: '写真による認識',
        appUseCase9: '写真で商品を検索',
        appUseCase10: '写真で洋服を検索',
        appUseCase11: '顔で検索',
        appUseCase12: '写真でクローンを検索',

        // AboutUs page
        aboutUsPageDevelopedBy: '{0}によって開発されました',
        aboutUsPageContactUs: 'お問い合わせ',
        aboutUsFollowUs: 'フォローする',

        // General
        privacyPolicy: '個人情報保護方針',

        // Redirect page
        redirectPageOpen: '開く',

        // Drawer
        drawerItemSearch: '探す',
        drawerItemAboutUs: '私たちに関しては',

        // Error
        errorSomethingWentWrong: '何か問題が発生しました。しばらくしてからもう一度お試しください',
        errorTooManyRequests: 'リクエストの頻度が高すぎます。しばらくしてからもう一度お試しください',
        errorContentFilter: '画像にはヌードが含まれます。 ヌードが含まれないように画像をトリミングしてください。',

        // Cookie consnet
        cookieConsentMessage: 'このウェブサイトはクッキーを使用してユーザーエクスペリエンスを向上させます。',
        cookieConsentButton: 'とった！',
    },

    ar: {
        // App head
        appTitle: 'Photo Sherlock: البحث بالصور',
        appMetaDescription: 'احصل على معلومات حول الصورة على الإنترنت  بنقرات قليلة',

        // SearchImage page
        searchImagePageDropImageText: 'أفلت الصورة هنا',
        searchImagePageSearchButton: 'بحث',
        searchImagePageLinkDescriptionText: 'سيكون الرابط متاحًا لمدة 24 ساعة فقط',
        searchImagePageIhancerAdMessage: 'حسِّن جودة الصور باستخدام تطبيق {0} للحصول على نتائج بحث أفضل',
        searchImagePageSelferAdMessage: 'التقط صورة مع تطبيق {0}',
        searchImagePageImagePreviewAlt: 'معاينة',
        searchImagePageWNTDAdMessage: 'تبحث عن شخص؟ جرب تطبيقنا',
        searchImagePageGamezopAdMessage: 'هل تريد أن تلعب لعبة؟',
        searchImagePageCropText: 'تقليم',
        supportUkraine: 'دعم أوكرانيا',

        // App description
        aboutApp: 'حول التطبيق',
        appPromoTitle: 'ابحث بالصور على الإنترنت  بنقرات قليلة',
        appDescription: 'يوفر التطبيق البحث عن طريق الصورة المأخوذة من الكاميرا أو من المعرض. يمكن استخدامه للعثور على معلومات حول الصورة على الإنترنت ، على سبيل المثال لاكتشاف المالك الحقيقي للصورة من الشبكة الاجتماعية (تحقق مما إذا كانت الصورة مزيفة). يحتوي على وظائف المحاصيل.',

        features: 'سمات',
        appFeature1: 'التقط الصور بسرعة وسهولة',
        appFeature2: 'إمكانية اختيار الصورة من المعرض',
        appFeature3: 'البحث بالصور مع محركات البحث',
        appFeature4: 'إمكانية قص الصورة قبل البحث لإزالة المناطق غير المرغوب فيها',
        appFeature5: 'يتم تقليل حجم الصورة تلقائيًا لتوفير تجربة بحث سريعة',

        typicalUseCases: 'حالات الاستخدام النموذجية',
        appUseCase1: 'ابحث عن شخص بالصورة',
        appUseCase2: 'كشف ما إذا كانت الصورة مزيفة',
        appUseCase3: 'ابحث بالصور في الشبكات الاجتماعية مثل Instagram و Facebook وغيرها',
        appUseCase4: 'البحث في صفحات الويب حيث يتم استخدام الصورة',
        appUseCase5: 'تأكد من أن هذا الشخص من تطبيق المواعدة حقيقي',
        appUseCase6: 'ابحث عن الصورة الأصلية',
        appUseCase7: 'ابحث عن الصورة بجودة أفضل',
        appUseCase8: 'الاعتراف بالصور',
        appUseCase9: 'البحث عن المنتجات بالصور',
        appUseCase10: 'ابحث عن الملابس بالصور',
        appUseCase11: 'البحث عن طريق الوجه',
        appUseCase12: 'البحث عن الحيوانات المستنسخة عن طريق الصورة',

        // AboutUs page
        aboutUsPageDevelopedBy: 'من تطوير {0}',
        aboutUsPageContactUs: 'اتصل بنا',
        aboutUsFollowUs: 'تابعنا',

        // General
        privacyPolicy: 'سياسة خاصة',

        // Redirect page
        redirectPageOpen: 'لفتح',

        // Drawer
        drawerItemSearch: 'بحث',
        drawerItemAboutUs: 'معلومات عنا',

        // Error
        errorSomethingWentWrong: 'حدث خطأ ما ، حاول مرة أخرى في وقت لاحق',
        errorTooManyRequests: 'أنت تقدم الطلبات كثيرًا ، يرجى المحاولة مرة أخرى لاحقًا',
        errorContentFilter: 'الصورة تحتوي على عُري. يرجى قص الصورة بحيث لا تحتوي على عري.',

        // Cookie consnet
        cookieConsentMessage: 'يستخدم هذا الموقع ملفات تعريف الارتباط لتعزيز تجربة المستخدم.',
        cookieConsentButton: 'فهمتك!',
    }
})

export function getDeviceLanguage() {
    return localizedStrings.getInterfaceLanguage().split('-')[0]
}
import React from 'react';
import './SearchImage.css';
import Dropzone from 'react-dropzone'
import BackendService from '../../model/services/BackendService';
import ProgressButton from '../../view/ProgressButton';
import StoresButtons from '../../view/StoresButton/StoresButtons'
import { Snackbar, ButtonGroup, Button, IconButton, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { localizedStrings } from "../../util/LocalizedStrings";
import Drawer from '../../view/Drawer/Drawer';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import ImageHelper from '../../util/ImageHelper';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import CropIcon from '@material-ui/icons/Crop';
import { Image } from '@material-ui/icons';
import Ad from '../../view/Ad';
import CookieConsent from "react-cookie-consent";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigationManager from '../../util/NavigationManager';
import { withTheme } from '@material-ui/core/styles';

class SearchImage extends React.Component {

    backendService = new BackendService();

    searchTypes = {
        BING: 'bing',
        FACE_SHERLOCK: 'face_sherlock',
        ACTOR_SHERLOCK: 'actor_sherlock'
    }

    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            isDescriptionExpanded: false
        }
        this.keyPressed = this.keyPressed.bind(this);
    }

    async keyPressed(event) {
        if (!this.state.showCrop) return;
        const enterKeyCode = 13;
        const escapeKeyCode = 27;
        switch (event.keyCode) {
            case enterKeyCode:
                this.applyCrop();
                break;
            case escapeKeyCode:
                this.cancelCrop();
                break;
            default:
        }
    }

    applyCrop = async () => {
        let croppedImage = await ImageHelper.getCroppedImg(this.state.imageForCrop, this.state.crop);
        this.setState({
            imageFile: croppedImage,
            imageUrl: null,
            showCrop: false
        });
    }

    cancelCrop = () => {
        this.setState({
            showCrop: false
        });
    }

    componentDidMount() {
        this.handleQueryParams()
        document.addEventListener("keydown", this.keyPressed, false);
        this.setDefaultCrop();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyPressed, false);
    }

    handleQueryParams() {
        const imageUrl = new URLSearchParams(window.location.search).get('image_url');
        if (imageUrl) {
            this.setState({ imageUrl: imageUrl })
        }
    }

    render() {
        this.updateBackgroundColor();
        return this.state.showCrop
            ? (this.renderCrop())
            : (
                <div className="RootSearchImage">
                    {this.renderTitle()}
                    {this.renderPromo()}
                    {this.renderAd()}
                    {this.renderDropzone()}
                    {this.renderIhancerAd()}
                    {this.renderSearchButtons()}
                    {this.renderWarInfo()}
                    {this.renderErrorSnackbar()}
                    {this.renderDescription()}
                    <StoresButtons />
                    {this.renderSocialButtons()}
                    {this.renderCookieConsent()}
                    {this.renderPrivacyPolicyLink()}
                    <Drawer />
                </div>
            );
    }

    updateBackgroundColor() {
        document.body.style.backgroundColor = this.state.showCrop ? "black" : "white";
    }

    renderSocialButtons() {
        return (
            <div className='SocialButtons'>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/appsmotor/">
                    <img src="images/Instagram_Glyph_Gradient.png" alt="appsmotor instagram" />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCoYfwa_dE1AY78ID_VNn7og">
                    <img src="images/youtube_social_icon_red.png" alt="appsmotor instagram" />
                </a>
            </div>
        )
    }

    renderCookieConsent() {
        return <CookieConsent
            location="bottom"
            buttonText={localizedStrings.cookieConsentButton}
            style={{ background: "black" }}
            buttonStyle={{ background: "white", color: "black", fontSize: '16px' }}
        >
            {localizedStrings.cookieConsentMessage}
        </CookieConsent>
    }

    renderDescription() {
        return <div className='AppDescription'>
            <Accordion
                expanded={this.state.isDescriptionExpanded}
                onChange={() => this.descriptionTapped()}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <b>{localizedStrings.aboutApp}</b>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {localizedStrings.appDescription}
                        <h4>{localizedStrings.features}:</h4>
                        <ul>
                            {[...Array(5)].map((_, i) =>
                                <li key={i}>{localizedStrings['appFeature' + (i + 1)]}</li>
                            )}
                        </ul>
                        <h4>{localizedStrings.typicalUseCases}:</h4>
                        <ul>
                            {[...Array(12)].map((_, i) =>
                                <li key={i}>{localizedStrings['appUseCase' + (i + 1)]}</li>
                            )}
                        </ul>
                        <b>{localizedStrings.appDisclaimerTitle}:</b>
                        &nbsp;{localizedStrings.appDisclaimer}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    }

    descriptionTapped() {
        let value = !this.state.isDescriptionExpanded
        this.setState({ isDescriptionExpanded: value });
    }

    onDrop = (files) => {
        this.setState({
            imageFile: files[0],
            imageUrl: null,
            imageFileDataURL: null
        });
        this.setDefaultCrop();
    }

    async uploadImage() {
        this.setState({ isUploading: true });
        try {
            var file = this.state.imageFile
            var resize = true
            var imageUrl = this.state.imageUrl
            if (imageUrl != null) {
                let imageResponse = await fetch(imageUrl);
                file = await imageResponse.blob();
                resize = false
            }
            imageUrl = await this.backendService.uploadImage(file, resize);
            this.setState({ imageUrl: imageUrl });
        } catch (exception) {
            this.setState({ error: exception.message });
        }
        this.setState({ isUploading: false });
        return imageUrl;
    }

    cropClicked = async () => {
        if (this.state.isPreparingForCrop) return;
        let readFileForCrop = (file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({
                    imageFileDataURL: reader.result,
                    showCrop: true
                })
                stopPreparingForCrop();
            };
            reader.onabort = stopPreparingForCrop;
            reader.onerror = handleError;
        }
        let startPreparingForCrop = () => {
            this.setState({ isPreparingForCrop: true });
        };
        let stopPreparingForCrop = () => {
            this.setState({ isPreparingForCrop: false });
        };
        let handleError = () => {
            stopPreparingForCrop();
            this.setState({ error: localizedStrings.errorSomethingWentWrong });
        }

        let imageFile = this.state.imageFile;
        let imageUrl = this.state.imageUrl;
        if (this.state.imageFileDataURL != null) {
            this.setState({ showCrop: true });
        } else if (imageFile != null) {
            startPreparingForCrop();
            readFileForCrop(imageFile);
        } else if (imageUrl != null) {
            startPreparingForCrop();
            var request = new XMLHttpRequest();
            request.open('GET', imageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                readFileForCrop(request.response);
            };
            request.onabort = stopPreparingForCrop;
            request.ontimeout = stopPreparingForCrop;
            request.onerror = handleError
            request.send();
        }
    }

    async searchImage(type) {
        var url;
        var imageUrl = this.state.imageUrl
        switch (type) {
            case this.searchTypes.BING:
                url = 'https://www.bing.com/images/search?view=detailv2&iss=sbi&form=SBIHMP&sbisrc=UrlPaste&idpbck=1&selectedindex=0&ccid=LtxF%2B8a5&vt=2&sim=11&q=imgurl:'
                    + imageUrl + '&id=' + imageUrl + '&mediaurl=' + imageUrl
                break
            case this.searchTypes.FACE_SHERLOCK:
                url = 'https://facesherlock.com/?image_url=' + imageUrl
                break
            case this.searchTypes.ACTOR_SHERLOCK:
                url = NavigationManager.getActorSherlockUrl(imageUrl)
                break
            default: break
        }
        window.open(url)
    }

    renderSearchButtons() {
        let isUploading = this.state.isUploading
        let imageUrl = this.state.imageUrl
        if (imageUrl == null
            || imageUrl.startsWith('https://nyc3.digitaloceanspaces.com/dsk/')
            || isUploading) {
            return this.state.imageFile != null || imageUrl != null || isUploading
                ? (
                    <div className='SearchButtons'>
                        <ProgressButton title={localizedStrings.searchImagePageSearchButton}
                            inProgress={this.state.isUploading}
                            onClick={() => this.uploadImage()} />
                    </div>
                )
                : null
        } else {
            return (
                <div className='SearchButtons'>
                    <ButtonGroup variant="contained" color='secondary' orientation="vertical">
                        <Button onClick={() => this.searchImage(this.searchTypes.BING)}>
                            {localizedStrings.searchImagePageSearchInBingButton}
                        </Button>
                        <Button onClick={() => this.searchImage(this.searchTypes.FACE_SHERLOCK)}>
                            {localizedStrings.searchImagePageSearchWithFaceSherlockButton}
                        </Button>
                        <Button onClick={() => this.searchImage(this.searchTypes.ACTOR_SHERLOCK)}>
                            {localizedStrings.searchImagePageSearchWithActorSherlockButton}
                        </Button>
                    </ButtonGroup>
                </div>
            )
        }
    }

    renderAd() {
        return <div className="Ad"><Ad /></div>;
    }

    renderWarInfo() {
        return <a className="WarInfo"
            style={{ backgroundImage: 'url(images/ua_flag.jpg)' }}
            href={NavigationManager.getSupportUkraineUrl()}
            target="_blank"
            rel="noopener noreferrer">
            <span>{localizedStrings.supportUkraine}</span>
        </a>;
    }

    renderWNTDAd() {
        return <div className="WNTDAd">
            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.appsmotor.wntd">
                {localizedStrings.searchImagePageWNTDAdMessage} <b>{localizedStrings.WNTD}</b>
            </a>
        </div>;
    }

    errorSnackbarOnClose = () => {
        this.setState({ error: null })
    }

    renderErrorSnackbar() {
        return (
            <Snackbar open={this.state.error} autoHideDuration={4000} onClose={this.errorSnackbarOnClose}>
                <Alert severity="error" onClose={this.errorSnackbarOnClose}>
                    {this.state.error}
                </Alert>
            </Snackbar>
        );
    }

    renderTitle() {
        return <h2 style={{'color': this.props.theme.palette.secondary.main}}>Photo Sherlock</h2>
    }

    renderPromo() {
        return <h2>{localizedStrings.appPromoTitle}</h2>
    }

    renderDropzone() {
        const imageFile = this.state.croppedImage ?? this.state.imageFile
        const imageUrl = imageFile ? URL.createObjectURL(imageFile) : this.state.imageUrl
        return (
            <div className='DropzoneContainer'>
                <Dropzone
                    multiple={false}
                    onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <section>
                            <div {...getRootProps()} className={isDragActive ? 'Dropzone Transparent' : 'Dropzone'}>
                                <input {...getInputProps()} />
                                {
                                    imageUrl == null
                                        ? <div className='DropzoneTextContainer'>
                                            <Image fontSize='large' color='disabled' />
                                            <span className='DropzoneText'>{localizedStrings.searchImagePageDropImageText.toUpperCase()}</span>
                                        </div>
                                        :
                                        <img alt={localizedStrings.searchImagePageImagePreviewAlt}
                                            src={imageUrl}
                                            className='DropzoneImage' />
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.renderCropButton()}
                {this.renderSelferAd()}
            </div>
        )
    }

    renderIhancerAd() {
        return <div className="IhancerAd">
            <a target="_blank" rel="noopener noreferrer" href="https://ihancer.com">
            {localizedStrings.formatString(localizedStrings.searchImagePageIhancerAdMessage, <span><b>ihancer</b></span>)}
            </a>
        </div>;
    }

    renderPrivacyPolicyLink() {
        return <div className="PrivacyPolicyLink">
            <a target="_blank" rel="noopener noreferrer" href={NavigationManager.privacyPolicyUrl}>
                {localizedStrings.privacyPolicy}
            </a>
        </div>;
    }

    renderCropButton() {
        let button = (
            <div className='DropzoneCropButton'>
                <CropIcon style={{ fontSize: 16 }} />
                &nbsp;
                {localizedStrings.searchImagePageCropText.toUpperCase()}
            </div>
        );
        let progressBar = <CircularProgress size={18} style={{color: 'white'}} />
        return this.state.imageFile != null || this.state.imageUrl != null
            ? <div className='DropzoneCropContainer' onClick={this.cropClicked}>
                {this.state.isPreparingForCrop ? progressBar : button}
            </div>
            : null
    }

    renderSelferAd() {
        return <a target="_blank" rel="noopener noreferrer" href="https://selfercamera.com">
            <div className='DropzoneSelferContainer'>
                {localizedStrings.formatString(localizedStrings.searchImagePageSelferAdMessage, <b>selfer</b>)}
            </div>
        </a>
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropImageLoaded = image => {
        this.setState({ imageForCrop: image });
    };

    setDefaultCrop() {
        this.setState({ crop: { unit: '%', x: 0, y: 0, width: 100, height: 100 } });
    }

    renderCrop() {
        return this.state.showCrop ? (
            <div className='Crop'>
                <ReactCrop
                    src={this.state.imageFileDataURL ?? this.state.imageUrl}
                    crop={this.state.crop}
                    onChange={this.onCropChange}
                    onImageLoaded={this.onCropImageLoaded}
                />
                <div className='CropButtonsContainer'>
                    <div className='CropButtons'>
                        <IconButton onClick={this.cancelCrop}>
                            <ClearIcon style={{ color: 'white' }} />
                        </IconButton>
                        <IconButton onClick={this.applyCrop}>
                            <DoneIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default withTheme(SearchImage)
import React, { Component } from 'react';
import { AnalyticsEventName, AnalyticsService } from '../model/services/AnalyticsService';

export default class Ad extends Component {
    
    messageEvent = 'message';

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        window.addEventListener(this.messageEvent, this.handleMessageEvent);
    }

    componentWillUnmount() {
        window.addEventListener(this.messageEvent, this.handleMessageEvent);
    }

    handleMessageEvent(event) {
        try {
            let message = JSON.parse(event.data);
            if (message && message.msg_type === 'resize-me') {
                for (let index in message.key_value) {
                    let key = message.key_value[index].key;
                    let value = message.key_value[index].value;
                    if (key === 'r_nh' && value === '0') {
                        AnalyticsService.logEvent(AnalyticsEventName.adsenseFailedToShow);
                        return;
                    }
                }
            }
        } catch (_) {}
    }

    render() {
        return (
            <ins className='adsbygoogle'
            style={{display: 'block'}}
            data-ad-client='ca-pub-5591173267528583'
            data-ad-slot='4921098465'
            data-ad-format='auto'
            // data-adtest="on"
            data-full-width-responsive='true' />
        );
    }
}
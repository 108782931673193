import { getDeviceLanguage } from './LocalizedStrings';

export default class NavigationManager {

    static privacyPolicyUrl = 'https://photo-sherlock.s3.eu-central-1.amazonaws.com/privacy_policy.html';

    static openUrlInNewTab(url) {
        window.open(url, '_blank');
    }

    static getSupportUkraineUrl() {
        switch (getDeviceLanguage()) {
            case 'es': return 'https://war.ukraine.ua/es/apoya-a-ucrania/'
            case 'de': return 'https://war.ukraine.ua/de/unterstuetzen-sie-die-ukraine/'
            case 'fr': return 'https://war.ukraine.ua/fr/soutenir-l-ukraine/'
            case 'ar': return 'https://war.ukraine.ua/ar/support-ukraine/'
            case 'zh': return 'https://war.ukraine.ua/zh/support-ukraine/'
            default: return 'https://war.ukraine.ua/support-ukraine/'
        }
    }

    static getActorSherlockUrl(imageUrl) {
        let linkUrl = new URL("https://actorsherlock.com/recognize");
        linkUrl.searchParams.set("image_url", imageUrl);
        let dynamicLinkUrl = new URL("https://asherlock.page.link");
        dynamicLinkUrl.searchParams.set("link", linkUrl.href);
        dynamicLinkUrl.searchParams.set("apn", "com.appsmotor.actor_sherlock");
        dynamicLinkUrl.searchParams.set("ibi", "com.appsmotor.actor-sherlock");
        dynamicLinkUrl.searchParams.set("isi", "1481261396");
        dynamicLinkUrl.searchParams.set("ofl", "https://actorsherlock.com");
        return dynamicLinkUrl.href;
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SearchImage from './controller/SearchImage/SearchImage';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AboutUs from './controller/AboutUs/AboutUs';
import Redirect from './controller/Redirect/Redirect';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#2962ff'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path='/redirect'>
            <Redirect />
          </Route>
          <Route path='/about'>
            <AboutUs />
          </Route>
          <Route path='/'>
            <SearchImage />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import ImageHelper from "../../util/ImageHelper";
import { localizedStrings } from "../../util/LocalizedStrings";

const backendUrl = 'https://photosherlock.com/api/';
const StatusCodeError = {
    tooManyRequests: 429,
    badRequest: 400
}

export default class BackendService {
    async uploadImage(file, resize) {
        if (resize) file = await ImageHelper.resize(file, 512)
        const data = new FormData()
        data.append('file', file)
        const response = await fetch(backendUrl + Endpoint.uploadImage, {
            method: 'POST',
            body: data,
            headers: {
                'Version': '2'
            }
        })
        if (!response.ok) {
            var errorMessage = localizedStrings.errorSomethingWentWrong
            switch (response.status) {
                case StatusCodeError.tooManyRequests:
                    errorMessage = localizedStrings.errorTooManyRequests
                    break
                case StatusCodeError.badRequest:
                    const json = await response.json()
                    if (json['content_filter_error'] == 'nd') 
                        errorMessage = localizedStrings.errorContentFilter
                    break
            }
            throw new Error(errorMessage)
        }
        const json = await response.json()
        return json['url']
    }
}

class Endpoint {
    static uploadImage = 'upload-image'
}
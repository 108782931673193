import React from 'react';
import {Button, CircularProgress} from '@material-ui/core';

export default class ProgressButton extends React.Component {
    render() {
        return (
            <Button variant='contained' color='secondary' onClick={this.props.onClick} disabled={this.props.inProgress}>
                {!this.props.inProgress && this.props.title}
                {this.props.inProgress && <CircularProgress size={18} />}
            </Button>
        );
    }
}
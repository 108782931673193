import './Redirect.css';
import React from 'react';
import {
    isAndroid,
    isIOS
} from "react-device-detect";
import { PHOTO_SHERLOCK_APP_STORE_LINK, PHOTO_SHERLOCK_GOOGLE_PLAY_LINK } from '../../view/StoresButton/StoresButtons'
import ProgressButton from '../../view/ProgressButton';
import { localizedStrings } from "../../util/LocalizedStrings";
import { AnalyticsService, AnalyticsEventName } from '../../model/services/AnalyticsService';
import { Route } from 'react-router-dom';

export default class Redirect extends React.Component {
    render() {
        return <div className="RootRedirect">
            <ProgressButton title={localizedStrings.redirectPageOpen} onClick={() => this.forceUpdate()} />
            <Route render={({ history }) => {
                this.handleQueryParams();
                if (isIOS) {
                    this.openUrl(PHOTO_SHERLOCK_APP_STORE_LINK);
                } else if (isAndroid) {
                    this.openUrl(PHOTO_SHERLOCK_GOOGLE_PLAY_LINK);
                } else {
                    history.push('/')
                }
            }} />
        </div>;
    }

    openUrl(url) {
        window.location = url;
    }

    handleQueryParams() {
        let source = new URLSearchParams(window.location.search).get('source');
        let supportedSources = ['doubleday_heroine', 'doubleday_brodude', 'doubleday_banner', 'appsmotor_youtube', 'geo_prosvetitel'];
        if (source && supportedSources.includes(source.toLowerCase())) {
            AnalyticsService.logEvent(AnalyticsEventName.openedFrom + '_' + source);
        }
    }
}
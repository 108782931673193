import { List, ListItem, ListItemText, Drawer as DrawerMaterial, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { localizedStrings } from '../../util/LocalizedStrings';
import './Drawer.css';

export default class Drawer extends React.Component {

    items = [
        {
            title: localizedStrings.drawerItemSearch,
            link: '/'
        },
        {
            title: localizedStrings.drawerItemAboutUs,
            link: '/about'
        }
    ]

    constructor(props) {
        super(props);
        this.state = { isOpen: false }
    }

    toggleDrawer(isOpen) {
        this.setState({ isOpen: isOpen })
    }

    render() {
        return (
            <div className="DrawerRoot">
                <DrawerMaterial
                    open={this.state.isOpen}
                    onClose={() => this.toggleDrawer(false)}>
                    <List className='List'>
                        {
                            this.items.map((item, i) =>
                                <ListItem
                                    button
                                    divider
                                    component={Link}
                                    to={item.link}
                                    key={i}
                                    onClick={() => this.toggleDrawer(false)}>
                                    <ListItemText
                                        primary={item.title}
                                    />
                                </ListItem>
                            )
                        }

                    </List>
                </DrawerMaterial>
                <IconButton onClick={() => this.toggleDrawer(true)}>
                    <MenuIcon color='secondary' />
                </IconButton>
            </div>
        );
    }
}
import imageCompression from 'browser-image-compression'

export default class ImageHelper {
    static async resize(file, maxWidthOrHeight) {
        const options = { maxWidthOrHeight: maxWidthOrHeight }
        const blob = await imageCompression(file, options)
        return new File([blob], file.name, {type: file.type})
    }

    static getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }
}